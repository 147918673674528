.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh !important;
    max-width: 150px;
    padding: 0 0 0;
    background: #fff !important;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    font-size: 24px;
    display: block !important;
    z-index: 9;
}

.nav-link { color:#0098f2; font-size:18px; }
.active { background-color: #dee2e6; }
.active .nav-link { color:#0056b3; }

@media only screen and (max-width: 768px) {
    .sidebar { position:absolute; top:46px; max-width:100%; width:100%; min-height:100px !important; height:130px; border-bottom:2px solid #dee2e6; }
    .nav-link { padding:5px 10px; font-size:15px; }
}