.assessment { position:relative; padding:25px; }
.content { max-width:900px; min-height:200px; margin:0 auto 100px; padding:25px; background:#fff; font-size:14px; }
.assessment h2, .assessment h3 { margin-bottom:10px; font-size:25px; display:flex; text-transform:uppercase; text-align:center; }
.assessment h3 { margin:10px 0; padding:0 10px; font-size:16px; line-height:40px; background:#E5E5E5; }
.assessment .exam_label, .assessment .exam_split { margin:0 5px; padding:2px 10px; border-bottom:1px solid #E5E5E5; font-size:14px; font-weight:500; cursor:pointer; }
.assessment .exam_label:hover, .assessment .exam_label.active { background:#eee; color:#0098f2; }
.assessment .exam_label.v2, .assessment .exam_label.v3 { margin:0; display:inline-block; width:30%; border-bottom:1px solid #E5E5E5; text-align:center; font-weight:normal; }
.assessment .exam_label.v3 { border-left:1px solid #E5E5E5; }
.assessment .exam_split { display:inline-block; margin-right:0; width:38%; }
.assessment .last-child .exam_label, .assessment .last-child .exam_split { border-bottom:none; }
.left { float:left; margin:10px 0; }
.right { float:right; margin:10px 0; }
.assessment input, .assessment select, .assessment textarea { width:208px; height:30px; line-height:30px; }
.assessment input[type="button"] { width:auto; }
.assessment input[type="text"], .assessment input[type="number"], .assessment input[type="date"], .assessment input[type="datetime-local"], .assessment textarea { margin:5px 15px 5px 0; border:1px solid #C4C4C4; }
.assessment input:focus, .assessment textarea:focus { border:1px solid #888; }
.assessment input[type="radio"], .assessment input[type="checkbox"] { width:auto; height:auto; }
.assessment input[type="text"]:disabled { background:none; border:none; }
.assessment select { margin:5px 5px 5px 0; background:#E5E5E5; border:none; }
.assessment textarea { width:100%; min-height:100px; margin:0; }
.chartnav { position:fixed; bottom:0; left:0; width:100%; height:75px; border-top:1px solid #ddd; background:#fff; z-index:8; }
.chartnav .tabs { position:absolute; left:50%; transform:translate(-50%, 0%); display:flex; clear:both; font-size:12px; }
.chartnav .tab { padding:5px; width:100px; height:75px; text-align:center; cursor:pointer; }
.chartnav .tab.active { background:#eee; }
.chartnav .tab img { width:45px; height:45px; background:#0098f2; border:2px solid #0088d8; border-radius:50%; transition:all 150ms ease-in-out; }
.chartnav .tab.active img, .chartnav .tab:hover img { background:#007bff; border-color:#006ce0; }
.chartnav .tab b { display:block; font-weight:normal; color:#0098f2; } 
.chartnav .tab.active b { color:#007bff; font-weight:bold; }
.content input[type="checkbox"] { width:auto; height:auto; }
label { display:block; margin-bottom:0; }

@media only screen and (max-width: 992px) {
    .content { margin:30px auto 100px; padding:0; width:100%; }
}

@media only screen and (max-width: 768px) {
    .content { margin:120px auto; }
    .assessment { padding:0; }
    .assessment .exam_label, .assessment .exam_split { display:block; width:100%; }
    .assessment .exam_label.v2, .assessment .exam_label.v3 { width:50%; }
    .assessment input, .assessment select, .assessment textarea { max-width:100%; }
    .chartnav { height:55px; }
    .chartnav .tab { width:90px; height:55px; }
    .chartnav .tab b { display:none; }
}